<template>
  <div>
    <div class="welfares-header family">福利中心</div>
    <div class="welfares-main family">
      <button @click="cur = 0" class="yzys" >援之以手</button>
      <button @click="curbox" >福利宝箱</button>
      <button @click="curs">会员福利</button>
      <button @click="cur = 4" class="qqfl">qq福利群</button>
    </div>
    <div class="welfares-content">
      <!-- 援之以手 -->
      <div v-show="cur == 0" class="aid">
        <img src="@/assets/image/1.png" alt="">
        <div class="rules">活动规则</div>
        <div class="welfares-footer">
        </div>
      </div>
      <!-- qq福利群 -->
      <div v-show="cur == 4">
        <div class="qq-group">
          <img src="@/assets/image/10.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'center',
  data() {
    return {
      cur: 0
    }
  },
  mounted(){
    let yzys = document.querySelector('.welfares-main .yzys')
    yzys.style = 'border:1px solid #9FDA35;color:#9FDA35 '
    let qqfl = document.querySelector('.welfares-main .qqfl')
    qqfl.style = ''
  },
 updated(){
  if (this.cur==0) {
    let yzys=document.querySelector('.welfares-main .yzys')
    yzys.style='border:1px solid #9FDA35;color:#9FDA35 '
     let qqfl = document.querySelector('.welfares-main .qqfl')
      qqfl.style = ''
  }
  if(this.cur==4){
      let yzys = document.querySelector('.welfares-main .yzys')
      yzys.style = ''
      let qqfl = document.querySelector('.welfares-main .qqfl')
      qqfl.style = 'border:1px solid #9FDA35;color:#9FDA35 '
  }
 },
  methods: {
    curs(){
      this.$router.push({ name: "memberVip" });
    },
    curbox(){
      this.$router.push({ name: "outOfTheFree" });
    }
  }
}
</script>

<style lang="scss" scoped>
.dhtitle{
  color: #9FDA35;
}
.welfares-header {
  width: 310px;
  height: 71px;
  margin: 25px auto;
  background: url('../../assets/image/12.png');
  background-size: 100% 100%;
  // background: linear-gradient(146deg, #009FFF 0%, #009FFF 0%, #05377B 100%);
  // box-shadow: inset 1px 1px 0px 0px rgba(255, 255, 255, 0.5), 2px 5px 8px 0px #000000;
  opacity: 1;
  border-radius: 20px;
  text-align: center;
  line-height: 71px;
  font-size: 30px;
  font-weight: 400;
  color: #FFFFFF;
  -webkit-text-stroke: 1px black;
}

.welfares-main {
  width: 280px;
  height: 48px;
  margin: auto;
  display: flex;
  justify-content: space-between;


  button {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 1px solid RGBA(0, 159, 255, 1);
    font-size: 16px;
    color: RGBA(0, 159, 255, 1);
    font-weight: 400;
  }
}

.welfares-content {
  .aid {
    img {
      display: block;
      width: 285px;
      height: 380px;
      margin: 22px auto;
    }

    .rules {
      text-align: center;
      margin: 2px auto;
      color: RGBA(5, 55, 123, 1);
      font-size: 30px;
      font-style: italic;
      font-weight: 900;
      -webkit-text-stroke: 1px #9FDA35;
    }

    .welfares-footer {
      width: 258px;
      height: 90px;
      margin: 8px auto;
      color: RGBA(25, 129, 192, 1);
      font-size: 14px;
      font-weight: 400;
      text-align: start;
    }
  }

  .redpacket {
    margin: 20px auto;
    width: 300px;
    height: 378px;
    background-image: url("../../assets/image/3.png");
    background-size: cover;

    .command {
      text-align: center;
      padding-top: 145px;
      font-size: 30px;
      font-family: YouSheBiaoTiHei-Bold, YouSheBiaoTiHei;
      font-weight: 900;
      color: rgba(0, 0, 0, 0);
      -webkit-text-stroke: 2px #FF0000;
    }

    input[type=text]::placeholder {
      color: white;
    }

    input {
      display: block;
      width: 228px;
      height: 38px;
      border-radius: 10px;
      text-align: center;
      margin: auto;
      margin-top: 82px;
      background-color: RGBA(111, 15, 36, 1);
      font-size: 16px;
    }

    button {
      display: block;
      width: 228px;
      height: 38px;
      border-radius: 10px;
      text-align: center;
      margin: 10px auto;
      font-size: 16px;
      border: 1px solid RGBA(0, 159, 255, 1);
      background-color: RGBA(0, 159, 255, 1);
    }

    .recharge-redpacket {
      width: 90px;
      height: 26px;
      margin: 25px auto;
      font-weight: 400;
      color: RGBA(5, 55, 123, 1);
      -webkit-text-stroke: 1px #9FDA35;
      text-align: center;
      line-height: 26px;
    }
  }

  .activity-rules {
    font-size: 30px;
    margin: 25px auto;
    color: rgba(0, 0, 0, 0);
    -webkit-text-stroke: 1px #9FDA35;
    font-weight: 400;
    text-align: center;
  }

  .treasure-box {
    width: 330px;
    height: 255px;
    margin: auto;
    display: flex;
    justify-content: space-between;


    .free-box {
      width: 160px;
      height: 255px;
      background-image: url(../../assets/image/7.png);
      background-size: cover;

      .newpeople {
        font-size: 14px;
        text-align: center;
        font-weight: 700;
        color: #FFFFFF;
        margin-top: 16px;
      }

      .empty {
        width: 132px;
        height: 132px;
        border-radius: 20px;
        margin: auto;
        margin-top: 21px;
        background-color: #FFFFFF;
      }

      .cdk {
        width: 132px;
        height: 52px;
        margin: 6px auto;
        text-align: center;
        font-size: 12px;
      }
    }
  }

  .footer {
    display: flex;
    justify-content: space-around;
    align-items: center;

    .success {
      font-size: 22px;
      font-weight: 400;
      color: #9FDA35;
    }

    .failed {
      font-size: 22px;
      font-weight: 400;
    }
  }

  .my-grade {
    text-align: center;
    margin-top: 25px;
  }

  .grade-schedule {
    margin: 13px auto;
    width: 305px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .zero {
      font-size: 20px;
      font-weight: 400;
    }

    .progress-bar {
      width: 210px;
      height: 15px;
      background-image: url(../../assets/image/8.png);
      background-size: cover;
      background-color: transparent !important;

      div {
        width: 20px;
        height: 10px;
        background-color: #9FDA35;
        border-radius: 5px;
      }
    }

    .one {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .percent {
    text-align: center;

    span {
      font-size: 20px;
      font-weight: 400;
    }
  }

  .detail {
    height: 17px;
    margin-top: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;

    div {
      font-size: 16px;
      font-weight: 400;
    }
  }

  .main-box {
    margin-top: 30px;
    display: flex;
    justify-content: space-around;

    .first {
      width: 70px;
      height: 80px;
      background-image: url(../../assets/image/9.png);
      background-size: cover;
      text-align: center;
      line-height: 80px;
      color: #000000;
      font-weight: 400;
    }

    .second {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 18px;
        height: 18px;
        margin-right: 2px;
      }

      div {
        font-size: 12px;
        color: RGBA(159, 218, 53, 1);
      }
    }
  }

  .success-arrive {
    display: block;
    width: 228px;
    height: 38px;
    background-color: RGBA(0, 159, 255, 1);
    text-align: center;
    line-height: 38px;
    margin: auto;
    border-radius: 10px;
    border: 1px solid RGBA(0, 159, 255, 1);
    font-size: 16px;
    font-weight: 400;
  }

  .qq-group {
    width: 285px;
    height: 380px;
    padding-top: 90px;
    margin: 25px auto;
    background-image: url(../../assets/image/11.png);
    background-size: cover;

    img {
      display: block;
      width: 150px;
      height: 150px;
      margin: auto;
    }
  }
}
</style>